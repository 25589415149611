<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Cyberbullying
          </h2>
          <p class="hashText">- a bántalmazó is áldozat?</p></b-col>
      </b-row>

      <b-row class="maintText__row">
        <b-col cols="12" lg="6" class="mainText__col order-col">
          <img src="@/assets/images/bgr/cyber1.jpg" class="w-100 margin-top-sm-44"/>
        </b-col>

        <b-col cols="12" lg="6" class="side-gap">
          <p class="text">
            A bullying esetében a szerepek általában tiszták: vannak az áldozatok és a bántalmazók, akik bántják az áldozatot, valamint a kettejük közti skálán elhelyezkedő, többé-kevésbé bevonódó csatlósok, támogatók, kívülállók és védelmezők. De talán a legérdekesebb szerep a bántalmazónak jut, hiszen ő indítja el a cselekvést. De mégis mi veszi rá erre? Vajon a bántalmazó örökké a rossz szerepben ragad? Válhat az áldozat is bántalmazóvá és fordítva?
          </p>
          <p class="text">
            Amikor szerepeket veszünk fel, rendelkeznünk kell bizonyos tulajdonságokkal, hogy megfeleljünk ennek: nincs ez másként a bántalmazók esetében sem. Több rizikófaktor, bántalmazásra hajlamosító tényező létezik, melyek megjelenése önmagában nem jelenti azt, hogy itt bántalmazás fog történni, csupán statisztikailag magasabb az esélye. Az olyan logikus tulajdonságoktól kezdve, mint a testi fölény, a népszerűség, kisebb mértékű empátia és az erőszakhoz való vonzódás folytatódhat olyan érthető kapcsolódási pontokkal, mint a sikeres, ügyes, okos, vezető énhez való ragaszkodás, mindemellett pedig megjelenhet a szorongás is. A bántalmazó tehát sokszor maga is fél, és ez a félelem az, ami a látszat ellenére a tettek mezejére hajtja, hogy bebizonyítsa önmagának és másoknak, hogy ő az erősebb. Mindezt a másik „legyőzésével”, ami lovagi tornák hiányában megszégyenítéssel, verbális vagy fizikai erőszakkal vagy éppen a cyberbullying modern eszközeivel készteti behódolásra áldozatát. 
          </p>
          <p class="text">
            De mitől szoronghat egy látszólag vezető egyéniség, és mi hajthatja arra, hogy mások életét megkeserítse? Nem csupán a fizikai erőszaknál, de bármely viselkedésnél beszélhetünk modellekről, ahogy a szülőt utánozza, vagy éppen a szülő által okozott frusztrációt, fájdalmat adja át a „gyengébbnek” tartott áldozatnak. Ez lehet családi erőszak eredménye – még akkor is, ha ennek nem ő a közvetlen áldozata, csupán megfigyeli azt –, illetve a család más nehézségei is megjelenhetnek a bántalmazó viselkedésében, például, ha azt látja, hogy a családban elfogadott az agresszió. De szorongást okozhat még iskolai, baráti vagy más közösségi normáknak való megfelelés vagy korábbi bántalmazás is.
          </p>
          <p class="text">
            Éppen ezért amellett, hogy a bántalmazó viselkedésének következményei lesznek rá nézve (maga a bántalmazás ténye is negatívan befolyásolja őt), fontos foglalkozni azzal, milyen hatások érték őt, és ezeken hogyan lehet javítani. 
          </p>
        </b-col>
      </b-row>
    </b-container>
  <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Cyberbullying - a bántalmazó is áldozat?", 
        desc: "A bullying esetében a szerepek általában tiszták: vannak az áldozatok és a bántalmazók, akik bántják az áldozatot, valamint a kettejük közti skálán elhelyezkedő, többé-kevésbé bevonódó csatlósok, támogatók, kívülállók és védelmezők.", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Cyberbullying - a bántalmazó is áldozat?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "A bullying esetében a szerepek általában tiszták: vannak az áldozatok és a bántalmazók, akik bántják az áldozatot, valamint a kettejük közti skálán elhelyezkedő, többé-kevésbé bevonódó csatlósok, támogatók, kívülállók és védelmezők."        
    })
  }
}
</script>